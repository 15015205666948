import React from "react";
import Img from "gatsby-image";
import { graphql, StaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import LoadScript from "../utilities/loadScript";
import Form from "../contact/form";
const QueryComp = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          companyInfo: allDataJson(filter: { id: { eq: "siteMeta" } }) {
            edges {
              node {
                id
                companyInfo {
                  phone
                  email
                  name
                  address
                  rbq
                }
                recaptchaKey
              }
            }
          }
        }
      `}
      render={data => <Contact data={data} />}
    />
  );
};

const Contact = ({ data }) => {
  let recaptchaKey = data.companyInfo.edges[0].node.recaptchaKey;

  const { t } = useTranslation();
  return (
    <>
      <section className="module divider-top bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-9 m-auto text-center">
              <h1>{t("contactUs")}</h1>
              <p className="lead"><h4>{t("contactUsText")}</h4></p>
            </div>
          </div>

          <div className="row pt-5">
            <div className="col-md-8 m-auto">
              <Form recaptchaKey={recaptchaKey} />
              <div
                className="ajax-response text-center"
                id="contact-response"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default QueryComp;
