import React from "react";
import withI18next from "../components/utilities/withI18next";
import Layout from "../components/layout/layout";
import Contact from "../components/contact/contact";
import Hero from "../components/hero/hero"
import SEO from "../components/seo/seo"

const Page = props => {
  return (
    <>
      <SEO title={props.t("seoContactTitle")} desc={props.t("seoContactDescription")} />
      <Layout>
        <Hero image="contact"/>
        <Contact/>
      </Layout>
    </>
  );
};
export default withI18next({ ns: "common" })(Page);
