import React from "react";
export default class LoadExternalScript extends React.Component {
  static defaultProps = {
    onLoad: () => {},
    onError: () => {},
    script: null
  };
  script = null;
  componentDidMount() {
    const { props } = this;
    if (!document) {
      return;
    }
    if (props.alwaysReload || !document.getElementById(props.id)) {
      this.script = document.createElement("script");
      this.script.src = props.src;
      this.script.id = props.id;
      if (props.async) {
        this.script.async = true;
      }
      this.script.onload = props.onLoad;
      this.script.onerror = props.onError;

      if (document.body) {
        document.body.appendChild(this.script);
      }
    } else {
      props.onError("script already loaded");
    }

    return false;
  }
  componentWillUnmount() {
    if (this.script) {
      document.body.removeChild(this.script);
    }
  }
  render() {
    return null;
  }
}
