import React, { useState } from "react";
import { useTranslation } from "react-i18next";
const Form = ({ recaptchaKey, withAttachment }) => {
  const { t } = useTranslation();
  const [formState, setState] = useState({
    name: "",
    subject: "",
    to: "",
    phone: "",
    text: "",
    mielBot: null,
    attachment: null
  });
  const handleSubmit = e => {
    if (formState.mielBot !== null) {
      return; // form filled by bot.
    }
    fetch("/.netlify/functions/sendMail", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formState })
    })
      .then(() => alert("Success!"))
      .catch(error => alert(error));
    e.preventDefault();
  };
  const handleChange = e => {
    if (e.target.name === "attachment") {
      let fileReader = new FileReader();
      fileReader.onloadend = (fileReader, ev) => {
        setState({
          ...formState,
          attachment: fileReader.currentTarget.result
        });
      };
      fileReader.readAsDataURL(e.target.files[0]);
    } else {
      setState({ ...formState, [e.target.name]: e.target.value });
    }
  };
  return (
    <>
      <form
        name="contact"
        onSubmit={handleSubmit}
        method="post"
        id="contact-form"
        method="post"
        noValidate
      >
        <div className="d-none">
          <input
            className="form-control"
            type="text"
            name="miel-bot"
            value={formState.mielBot}
            onChange={handleChange}
          />
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="name"
                value={formState.name}
                onChange={handleChange}
                placeholder={t("formName")}
                required=""
              />
              <p className="help-block text-danger" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                name="to"
                placeholder={t("formEmail")}
                value={formState.to}
                onChange={handleChange}
                required=""
              />
              <p className="help-block text-danger" />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="subject"
                value={formState.subject}
                onChange={handleChange}
                placeholder={t("formSubject")}
                required=""
              />
              <p className="help-block text-danger" />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="phone"
                value={formState.phone}
                onChange={handleChange}
                placeholder={t("formPhone")}
                required=""
              />
              <p className="help-block text-danger" />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <textarea
                className="form-control"
                name="text"
                value={formState.text}
                onChange={handleChange}
                placeholder={t("formMessage")}
                rows="12"
                required=""
              />
            </div>
          </div>
          {withAttachment && (
            <div className="form-group">
              <label htmlFor="uploaded_file">
                Joindre votre curriculum vitae &nbsp;
              </label>
              <input
                type="file"
                onChange={handleChange}
                attachment
                name="attachment"
                id="attachment"
              />
            </div>
          )}

          <div className="col-md-12">
            <p className="text-center">
              <input
                className="btn btn-circle btn-brand"
                type="submit"
                value={t("formSend")}
              />
            </p>
          </div>

         
        </div>
      </form>
    </>
  );
};

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};
export default Form;
